<template>
  <div class="body_section">
    <profileHeader
      :showFilter="true"
      @changedGlobalclient="changedGlobalclient"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <!--<input class="search" v-model="serch_title" @keyup="serchTitle()"  type="text" placeholder="Search by business name" />-->
          </div>
          <div class="d-flex">
            <transactionsFilters
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
              :filterStatus="filterStatus"
              :selectedGlobalClient="selectedGlobalClient"
            ></transactionsFilters>
          </div>
        </div>
        <div class="list_table">
          <table class="table table-hover todo_table">
            <thead>
              <tr>
                <th scope="col">
                  <a
                    @click="sortMe('clientName')"
                    v-bind:class="{
                      sort_ascending: sortKeys['clientName'] == 1,
                      sort_descending: sortKeys['clientName'] != 1,
                    }"
                    >Client</a
                  >
                </th>
                <th scope="col">State</th>
                <th class="description" scope="col">Description</th>
                <th scope="col">Created On</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody v-if="list.length > 0">
              <template v-for="(item, index) in list">
                <tr :key="index">
                  <td>
                    <figure>
                      <span
                        ><img
                          @error="setAltImg"
                          :src="checkProperty(item, 'clientDetails', 'logo')"
                      /></span>

                      <figcaption>
                        {{ checkProperty(item, "clientDetails", "busName") }}
                      </figcaption>
                    </figure>
                  </td>
                  <td>
                    {{ checkProperty(item, "stateDetails", "name") }}
                  </td>

                  <td class="capfirst">
                    {{ checkProperty(item, "data", "description") }}
                  </td>
                  <td>{{ checkProperty(item, "createdOn") | formatDate }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link
                        :to="'/upload-returns/' + item['data']['filingId']"
                        class="upload_return"
                        ><img
                          src="@/assets/images/upload_icon1.svg"
                        />Upload</router-link
                      >
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <template v-if="list.length <= 0">
            <span class="no_data">No Data!</span>
          </template>
        </div>

        <div class="pagination_wrap">
          <div v-show="list.length > 0">
            <div class="pagination_content">
              <h6>Show Records</h6>
              <multiselect
                :multiple="false"
                @input="changePerPage()"
                placeholder="Page"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="true"
                :preserve-search="true"
                v-model="perPage"
                :options="perPeges"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} selected</span
                  >
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && isOpen"
                  ></span>
                </template>
              </multiselect>
            </div>
          </div>

          <b-pagination
            v-if="list.length > 0"
            v-model="page"
            :total-rows="totalPages"
            :per-page="perPage"
            pills
          ></b-pagination>
        </div>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>
<script>
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";

import Multiselect from "vue-multiselect-inv";
import transactionsFilters from "@/views/filters/transactionsFilters.vue";
import _ from "lodash";

export default {
  name: "notes",
  methods: {
    goTopage(item) {
      if (_.has(item, "data")) {
        if (item["data"]["navigationKey"] == "FILING_DETAILS") {
          this.$router.push("/upload-returns/" + item["data"]["filingId"]);
        }
      }
    },
    changedGlobalclient(cl) {
      this.selectedGlobalClient = cl;
      this.filterStatus = false;
      this.filterStatus = true;

      //this.$refs.rout_view.serchTitle(cl);
    },
    getList() {
      let postData = {
        filters: this.filterData,
        /*filters: {
          title: this.serch_title,
          roleIds: this.filterData.roleIds,
          statusIds: this.filterData.statusIds,
          createdOnRange: this.filterData.createdOnRange
        },
        */
        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      postData["filters"]["title"] = this.serch_title;

      this.list = [];
      this.$store
        .dispatch("getTodoList", postData)
        .then((response) => {
          this.list = response.list;
          this.totalPages = response.totalCount;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    applyFilters(filterData) {
      //this.filterData = filterData: { "title": "","clientIds":[] ,"statusIds": [], "stateIds": [], "createdOnRange": []},

      this.filterData.clientIds = filterData.clientIds;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.createdOnRange = filterData.createdOnRange;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      (this.filterData = {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
      }),
        this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;

        this.getList();
      }
    },
  },
  data() {
    return {
      selectedGlobalClient: null,
      filterStatus: true,

      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      list: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: {
        title: "",
        clientIds: [],
        statusIds: [],
        stateIds: [],
        createdOnRange: [],
      },
      serch_title: "",
      sortKeys: {
        name: 1,
        clientName: 1,
        createdOn: -1,
        email: 1,
        roleName: 1,
      },
    };
  },
  components: {
    transactionsFilters,
    Multiselect,
    profileHeader,
    footerView,
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "createdOn", order: -1 };

    this.sortKeys = {
      name: 1,
      clientName: 1,
      createdOn: -1,
      email: 1,
      roleName: 1,
    };

    this.selectedGlobalClient = this.$store.getters["getGlobalClient"];
    this.changedGlobalclient(this.selectedGlobalClient);
    //this.filterData.clientIds
    if (
      this.selectedGlobalClient != null &&
      _.has(this.selectedGlobalClient, "_id")
    ) {
      this.filterData.clientIds.push(this.selectedGlobalClient["_id"]);
    }
    this.getList();
  },
  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
};
</script>
